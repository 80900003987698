import { AnimatedTextBlock } from "@components/atoms/AnimatedTextBlock"
import { BlockItem } from "@components/atoms/BlockItem"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { HeroLogo } from "@components/svg/HeroLogo"
import { defineBlock, EditableText } from "eddev/blocks"
import { twMerge } from "tailwind-merge"

export const meta: BlockMeta = {
  title: "Home Image Text",
}

export default defineBlock("home/image-text", (props) => {
  return (
    <BlockItem className={twMerge("md:w-[calc(50%-8px)] md:ml-0 overflow-hidden bg-none", env.admin ? "md:w-full" : "")} innerClassName="md:h-[45vw]">
      <div className="flex justify-center items-center w-full md:h-full">
        <ResponsiveImage
          {...props.image}
          objectFit="cover"
          aspect={false}
          className="absolute w-full h-full [&_img]:!h-full left-0 top-0 block"
        />

        <div className="h-[97vw] w-full relative flex flex-col justify-center items-center md:h-full">
          <AnimatedTextBlock textKey="text" className="type-title-xl" textClassName="md:text-[0.9em] text-orange text-center" triggerStart="top bottom-=20%" triggerEnd="bottom top" placeholder="Let’s be the best place in the world to have herpes." repeat />
        </div>
      </div>
    </BlockItem>
  )
})